import { Box, Typography } from '@mui/material';
import React from 'react';
import {
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Area,
    ComposedChart,
    DefaultLegendContent,
} from 'recharts';

const AskBollingerBandChart = ({ ncgDataPoints, currency }) => {
    // Round prices to third decimal and add the currency to the values
    const formatPrice = (value) => {
        return value ? `${parseFloat(value)?.toFixed(3)} ${currency?.toUpperCase()}` : '';
    };

    // Take upper and lower band data from ncgDataPoints.asks and assign it to dataForShadedArea
    const dataForShadedArea = ncgDataPoints?.asks?.map(point => {
        return {
            date: point.date,
            shade: [point.upperBand, point.lowerBand]
        };
    }) || [];

    // Assign the dataForShadedArea to ncgDataPoints.asks
    const finalData = ncgDataPoints?.asks?.map((point, index) => {
        return {
            ...point,
            shade: dataForShadedArea[index].shade
        };
    }) || [];

    // Render the tooltip without the range
    const renderTooltipWithoutRange = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <Box
                    sx={{
                        backgroundColor: 'white',
                        padding: '10px',
                        border: '1px solid #ccc',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        textAlign: 'left'
                    }}
                >
                    <Typography>{`Date: ${label}`}</Typography>
                    <Typography sx={{ color: 'blue' }}>{`Price: ${formatPrice(payload[0].value)}`}</Typography>
                    <Typography sx={{ color: 'green' }}>{`SMA20: ${formatPrice(payload[1].value)}`}</Typography>
                    <Typography sx={{ color: 'red' }}>{`SMA5: ${formatPrice(payload[2].value)}`}</Typography>
                    <Typography sx={{ color: 'orange' }}>{`Upper Band: ${formatPrice(payload[3].value)}`}</Typography>
                    <Typography sx={{ color: 'lightgreen' }}>{`Lower Band: ${formatPrice(payload[4].value)}`}</Typography>
                </Box>
            );
        }
        return null;
    };

    // Render the legend without the range
    const renderLegendWithoutRange = ({ payload }) => {
        const filteredPayload = payload.filter((item) => item.dataKey !== 'shade');
        return (
            <DefaultLegendContent payload={filteredPayload} />
        );
    };


    return (
        <div style={{ textAlign: 'center', width: "100%" }}>
            <ResponsiveContainer width="100%" height={400}>
                <ComposedChart
                    data={finalData || []}
                    margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis
                        domain={['auto', 'auto']}
                        label={{
                            value: `${currency.toUpperCase()}/MWh`,
                            angle: -90,
                            position: 'insideLeft',
                        }}
                    />
                    <Tooltip
                        labelFormatter={(label) => `Date: ${label}`}
                        formatter={(value) => formatPrice(value)}
                        content={renderTooltipWithoutRange}
                    />
                    <Legend content={renderLegendWithoutRange} />
                    <Area
                        type="monotone"
                        dataKey="shade"
                        stroke="none"
                        fill="#8884d8"
                        fillOpacity={0.2}
                        isAnimationActive={false}
                    />
                    <Line
                        type="monotone"
                        dataKey="price"
                        stroke="blue"
                        strokeWidth={3}
                        dot={false}
                    />
                    <Line
                        type="monotone"
                        dataKey="sma"
                        stroke="green"
                        strokeDasharray="5 5"
                        name="sma20"
                        strokeWidth={3}
                        dot={false}
                    />
                    <Line
                        type="monotone"
                        dataKey="sma5"
                        stroke="red"
                        name="sma5"
                        strokeDasharray="5 5"
                        strokeWidth={3}
                        dot={false}
                    />
                    <Line
                        type="monotone"
                        dataKey="upperBand"
                        stroke="orange"
                        strokeWidth={2}
                        dot={false}
                    />
                    <Line
                        type="monotone"
                        dataKey="lowerBand"
                        stroke="lightgreen"
                        strokeWidth={2}
                        dot={false}
                    />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
};

export default AskBollingerBandChart;
